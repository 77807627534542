import { Divider, Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AutoWorksheet, CreditApplication, EFinancingProgram } from '../../../data/types'
import { FormatCurrency } from '../../../services/Formatter'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

type Props = {
  worksheet: AutoWorksheet
  creditApplication: CreditApplication
}

const TransactionSummary = ({ worksheet, creditApplication }: Props) => {
  const { t } = useTranslation()

  const autoPlanEnum = useAppSelector((state: TRootState) =>
    appSelectors.getPlanEnumForProgram(state, EFinancingProgram.Auto),
  )

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  const getSubTotalAfterMarket = () => {
    let ret = 0
    if (worksheet) {
      if (worksheet.extendedServiceTaxedAmount) ret += worksheet.extendedServiceTaxedAmount
      if (worksheet.lifeInsurance) ret += worksheet.lifeInsurance
      if (worksheet.gapInsurance) ret += worksheet.gapInsurance
      if (worksheet.accidentAndHealthInsurance) ret += worksheet.accidentAndHealthInsurance
      if (worksheet.taxesOnInsurance) ret += worksheet.taxesOnInsurance
    }
    return ret
  }

  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      divider={<Divider orientation="vertical" flexItem />}
      justifyContent="space-between"
      spacing={2}
    >
      {/** Vehicule section */}
      <Stack direction="column">
        <Typography variant="h6" component="div" gutterBottom>
          {t('worksheet.vehicle')}
        </Typography>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.price')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.price)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.rebate')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency((worksheet?.rebate ?? 0) * -1)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.netTradeInAllowance')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.tradeInVehicle?.netTradeInAllowance)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.installationAndDelivery')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.installationDelivery)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.licenseFee')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.licenseFee)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.dealerAdminFee')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.dealerAdminFee)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.otherTaxable')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.otherTaxable)}</Typography>
        </Stack>

        <Divider sx={{ mt: 1, mb: 1 }} />

        {worksheet?.computedVehicleAndFeesTaxes?.map((tax) => (
          <Stack direction="row" justifyContent="space-between" spacing={4} key={tax.taxId}>
            <Typography variant="subtitle1">
              {t(`enum.eTax.${tax.taxId}`)} (
              {
                worksheet.applicableTaxesOnVehicleAndFees?.find(
                  (applicableTaxesOnVehicleAndFees) => applicableTaxesOnVehicleAndFees.taxId === tax.taxId,
                )?.percentage
              }
              %) :
            </Typography>
            <Typography variant="subtitle1">{FormatCurrency(tax.value)}</Typography>
          </Stack>
        ))}
        {worksheet?.tradeInVehicle && (
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="subtitle2">
              <InfoIcon fontSize="small" /> {t('worksheet.tradeInTaxExemption')} :
            </Typography>
            <Typography variant="subtitle2">{FormatCurrency(worksheet?.tradeInVehicleTaxedAmount)} </Typography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.otherNonTaxable')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.otherNonTaxable)}</Typography>
        </Stack>

        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.subTotal')} :</Typography>
          <Typography variant="subtitle1">
            {FormatCurrency(worksheet?.totalAmountFinancedWithoutInsurancesAndExtendedWarranty)}
          </Typography>
        </Stack>
      </Stack>

      {/** Service après-ventre */}
      <Stack direction="column">
        <Typography variant="h6" component="div" gutterBottom>
          {t('worksheet.aftermarketService')}
        </Typography>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">
            {t('worksheet.extendedService')} ({t('worksheet.taxesIncluded')}) :
          </Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.extendedServiceTaxedAmount)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.lifeInsurance')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.lifeInsurance)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.gapInsurance')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.gapInsurance)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.accidentAndHealthInsurance')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.accidentAndHealthInsurance)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.taxesOnInsurance')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.taxesOnInsurance)}</Typography>
        </Stack>

        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.subTotal')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(getSubTotalAfterMarket())}</Typography>
        </Stack>
      </Stack>

      {/** Montant financé */}
      <Stack direction="column">
        <Typography variant="h6">{t('worksheet.amountFinanced')}</Typography>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.subTotalVehicle')} :</Typography>
          <Typography variant="subtitle1">
            {FormatCurrency(worksheet?.totalAmountFinancedWithoutInsurancesAndExtendedWarranty)}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.subTotalAftermarket')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(getSubTotalAfterMarket())}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.cashDown')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency((worksheet?.cashDown ?? 0) * -1)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.lenderAdminFee')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.icebergLenderAdminFee)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.ppsa')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.icebergPPSA)}</Typography>
        </Stack>

        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('common.total')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.totalAmountFinanced)}</Typography>
        </Stack>
      </Stack>

      {/** Financement */}
      <Stack direction="column">
        <Typography variant="h6">{t('worksheet.finance')}</Typography>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.totalAmountFinanced')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet?.totalAmountFinanced)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">Produits après vente : </Typography>
          {worksheet.aftermarketVsValue && <Typography variant="subtitle1">{worksheet.aftermarketVsValue}%</Typography>}
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheetHistory.plan')} :</Typography>
          <Typography variant="subtitle1">
            {worksheet.financingPlan !== undefined ? autoPlanEnum[worksheet.financingPlan][lang] : 'N/A'}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.interestRate')} :</Typography>
          <Typography variant="subtitle1">{creditApplication.finalCreditDecision.interestRate}%</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('credit.effectiveRate')} :</Typography>
          <Typography variant="subtitle1">{worksheet.effectiveRate}%</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.term')} :</Typography>
          <Typography variant="subtitle1">
            {worksheet.term} {t('worksheet.month')}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.paymentFrequency')} :</Typography>
          <Typography variant="subtitle1">{t(`worksheet.${worksheet.paymentFrequency}`)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="subtitle1">{t('worksheet.paymentAmount')} :</Typography>
          <Typography variant="subtitle1">{FormatCurrency(worksheet.paymentAmountForFrequency)}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TransactionSummary
