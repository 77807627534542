import { IApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: IApplicationInsights | null = null
const loggedErrors: Error[] = []
export function setupTracking(ai: IApplicationInsights): void {
  appInsights = ai
}

export function reportErrorToConsole(error: unknown) {
  console.error(error)
}
export function reportErrorToServer(error: Error) {
  if (loggedErrors.includes(error) === false) {
    reportErrorToConsole(error)
    appInsights?.trackException({ exception: error, severityLevel: 3 })
    loggedErrors.unshift(error)
  }
}
