import React from 'react'
import { useTheme, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DecisonIcon from './decisonIcon'
import { NormCreditDecision } from '../../../data/types/CreditDecisionSchema'
import { NormsMessage, NormsMessageKey, ValueListItem } from '../../../data/types'
import { appSelectors } from '../../../data/store/AppStore'
import { useAppSelector } from '../../../data/store'

type Props = {
  normDecision: NormCreditDecision | null
  normsMessage: NormsMessage[]
  applicationSuggestedAmount: number
}

const NormIceberg = ({ normsMessage, normDecision, applicationSuggestedAmount }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const creditRefusalReasons = useAppSelector<ValueListItem[]>(appSelectors.getCreditRefusalReasons)

  const handleReasonsToDisplay = (norm: NormsMessage) => {
    const reason = creditRefusalReasons.find((r) => r.id === norm.id)

    return reason?.text ?? t(`CreditDecisionNorms.${norm.message as NormsMessageKey}`)
  }

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.icebergStandards')}
      </Typography>
      <DecisonIcon decision={normDecision?.decision} />
      <Typography variant="subtitle1" component="p">
        {t(`credit.${normDecision?.decision ?? 'pending'}`)}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.interestRate')}: {normDecision?.interestRate}%
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.suggestedLoanAmountForApplication')}: ${applicationSuggestedAmount}
      </Typography>
      {normsMessage.map((norm) => (
        <Typography
          key={norm.id}
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {norm.applicantType && (t(`common.${norm.applicantType}`) as string).concat(':')}{' '}
          {handleReasonsToDisplay(norm)}
        </Typography>
      ))}
    </>
  )
}

export default NormIceberg
