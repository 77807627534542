import { Typography, IconButton, CardMedia, Stack, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TaxiAlert as TaxiAlertIcon } from '@mui/icons-material'
import { AutoWorksheet } from '../../../data/types'
import { FormatCurrency } from '../../../services/Formatter'

type VehiclePriceCategory = 'xclean' | 'clean' | 'avg' | 'rough'

type Props = {
  worksheet: AutoWorksheet
  onClick: () => void
  isVauto: boolean
  editDisabled: boolean
  userCanEditWorksheet: boolean
}

const VehicleEvaluationBox = ({ worksheet, onClick, isVauto, editDisabled, userCanEditWorksheet }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('worksheet.sideSection.vehicleEvaluation')}
      </Typography>
      <Stack direction="row" spacing={2} marginTop={1} marginBottom={3} justifyContent="center" alignContent="center">
        {isVauto && (
          <CardMedia component="img" sx={{ width: 'auto', height: 50 }} image="/images/vautoLogo.png" alt="Loading" />
        )}
        {worksheet.carfaxValue && (
          <IconButton disabled>
            <TaxiAlertIcon fontSize="large" color="error" />
          </IconButton>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">
          CBB (
          {worksheet.vehicle?.pricingCategory
            ? t(`worksheet.vehiclePriceCategory.${worksheet.vehicle.pricingCategory as VehiclePriceCategory}`)
            : ''}
          )
        </Typography>
        <Typography variant="subtitle1">
          {worksheet.thirdPartyVehicleValue ? FormatCurrency(worksheet.thirdPartyVehicleValue) : 'N/A'}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.sideSection.carfax')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet?.carfaxValue)}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.sideSection.marketValue')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet.finalVehicleValue)}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.sideSection.discretionaryAllocation')} :</Typography>
        <Typography variant="subtitle1">
          {FormatCurrency(worksheet.vehicleEvaluation?.discretionaryAllocation)}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.sideSection.authorizedSurplus')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet.authorizedSurplus)}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.sideSection.authorizedAmount')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet.authorizedAmount)}</Typography>
      </Stack>

      <Stack alignItems="center" justifyContent="center" marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={!worksheet.vehicle || editDisabled || !userCanEditWorksheet}
        >
          {t('worksheet.edit')}
        </Button>
      </Stack>
    </>
  )
}

export default VehicleEvaluationBox
