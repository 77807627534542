import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Reports = (): JSX.Element => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  return (
    <Button onClick={() => navigate('/reports/payoutThatSkippedIncomeValidation', { replace: true })}>
      {t('report.payoutWithoutFlinks.title')}
    </Button>
  )
}

export default Reports
