import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditApplication, EMerchantIds, EPaymentPlan, Merchant } from '../../../data/types'
import { FundingComputedDto } from '../../../data/types/FundingComputedDto'
import { IFinanceBaseWorksheet } from '../../../data/types/IFinanceBaseWorksheetSchema'
import { MerchantPaymentPlan } from '../../../data/types/MerchantPaymentPlan'
import { FormatCurrency, formatDate } from '../../../services/Formatter'
import FormattedMerchantPaymentPlan from '../../ViewCreditApplicationPage/components/FormattedMerchantPaymentPlan'

type Props = {
  worksheet: IFinanceBaseWorksheet
  creditApplication: CreditApplication
  computedInfo: FundingComputedDto
  paymentMerchantsByIds: Record<string, Merchant>
  totalObligation: number
  merchantPaymentPlan: MerchantPaymentPlan | null
}

const BORROWER_IDS: string[] = [EMerchantIds.PayCustomerByBankTransfer, EMerchantIds.PayCustomerByCheque]

const TransactionSection = ({
  worksheet,
  creditApplication,
  computedInfo,
  paymentMerchantsByIds,
  totalObligation,
  merchantPaymentPlan,
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const getMerchantName = useCallback(
    (id: string) => {
      if (BORROWER_IDS.some((n) => n === id) && creditApplication?.applicant.firstName) {
        return `${creditApplication.applicant.firstName} ${creditApplication.applicant.lastName}`
      }
      return paymentMerchantsByIds?.[id]?.name ?? ''
    },
    [paymentMerchantsByIds, creditApplication],
  )

  return (
    <Paper
      elevation={3}
      sx={{
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
        padding: 20,
        marginTop: '1rem',
        p: 1,
        mb: 3,
      }}
    >
      <Typography variant="h5" component="div" gutterBottom>
        Transaction
      </Typography>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-around"
        spacing={2}
      >
        {/** Financing section */}
        <Stack direction="column">
          <Typography variant="h6" component="div" gutterBottom>
            {t('taskManager.financing')}
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheet.paymentPlan')}&nbsp;:</Typography>
            <Typography variant="subtitle1">
              {worksheet?.paymentPlanId === EPaymentPlan.regularDailyInterests &&
                t(`enum.ePaymentPlan.${worksheet?.paymentPlanId}`)}
              {worksheet?.paymentPlanId !== EPaymentPlan.regularDailyInterests && merchantPaymentPlan && (
                <FormattedMerchantPaymentPlan merchantPaymentPlan={merchantPaymentPlan} />
              )}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.program')} :</Typography>
            <Typography variant="subtitle1">
              {creditApplication && t(`credit.plans.${creditApplication.finalCreditDecision.planName}`)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">
              {t('worksheetCommon.interest')} :
              {worksheet?.paymentPlanId === EPaymentPlan.regularDailyInterests &&
                `(${creditApplication?.finalCreditDecision?.interestRate}%)`}
            </Typography>
            <Typography variant="subtitle1">{FormatCurrency(computedInfo?.totalInterestAmount)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.totalObligation')} :</Typography>
            <Typography variant="subtitle1">{FormatCurrency(totalObligation)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.annualPercentageRate')} :</Typography>
            <Typography variant="subtitle1">{computedInfo.effectiveRate} %</Typography>
          </Stack>
        </Stack>

        {/** Borrower's payments section */}
        <Stack direction="column">
          <Typography variant="h6" component="div" gutterBottom>
            {t('worksheetCommon.borrowersPayments')}
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheet.term')} :</Typography>
            <Typography variant="subtitle1">
              {worksheet?.term} {t('worksheet.month')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheet.paymentFrequency')} :</Typography>
            <Typography variant="subtitle1">{t('worksheet.monthly')}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.payment')} :</Typography>
            <Typography variant="subtitle1">{FormatCurrency(worksheet?.paymentAmountForFrequency)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.activationDate')} :</Typography>
            <Typography variant="subtitle1">{worksheet?.deliveryOn}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.firstPaymentDate')} :</Typography>
            <Typography variant="subtitle1">{formatDate(worksheet?.firstPaymentOn)}</Typography>
          </Stack>
        </Stack>

        {/** Funding section */}
        <Stack direction="column">
          <Typography variant="h6" component="div" gutterBottom>
            {t('worksheet.funding')}
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1">{t('worksheetCommon.paymentDate')} :</Typography>
            <Typography variant="subtitle1">{formatDate(worksheet?.deliveryOn)}</Typography>
          </Stack>
          {worksheet?.merchantPayments && (
            <Stack direction="row" justifyContent="space-between" mt={2} spacing={2} width="30rem">
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('taskManager.merchantName')}</TableCell>
                      <TableCell align="center">{t('worksheetCommon.paymentMethod')}</TableCell>
                      <TableCell align="right">{t('worksheet.paymentAmount')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {worksheet.merchantPayments.map((row) => (
                      <TableRow key={row.merchantId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {getMerchantName(row.merchantId)}
                        </TableCell>
                        <TableCell align="center">{t(`enum.paymentMethods.${row.paymentMethod}`)}</TableCell>
                        <TableCell align="right">{FormatCurrency(row.amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default TransactionSection
