import { ButtonGroup, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Button from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { useAppSelector } from '@src/data/store'
import { getProgramsByCompanyId } from '@src/data/types/FinancingCompanies'
import { buildSelectValueListFromEnumWithTranslation } from '@src/components/buildSelectValueList'
import { userSelectors } from '@src/data/store/UserStore'
import { appSelectors } from '@src/data/store/AppStore'
import { TranslatedEnum } from '@src/data/types/TranslatedEnum'
import {
  CreditTaskDashboardFilters,
  EAssignmentFilterList,
  EEntryTypeList,
  EFinancingCompany,
  ELanguageList,
  EProvinceList,
} from '../../../data/types'
import { MultipleSelectCheckmarks, SelectComponent } from '../../../components'

type Props = {
  filterValues: CreditTaskDashboardFilters
  resetFilter: () => void
  applyFilter: (data: CreditTaskDashboardFilters) => void
  trusteeNames: string[]
}

const FilterComponent = ({ filterValues, resetFilter, applyFilter, trusteeNames }: Props) => {
  const [selectedLoanTypes, setSelectedLoanTypes] = React.useState<string[]>(filterValues.financingProgramIds)
  const [selectedEntryTypes, setSelectedEntryTypes] = React.useState<string[]>(filterValues.entryType)
  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number[]>(filterValues.correspondanceLanguageId)
  const [selectedAssignmentStatus, setselectedAssignmentStatus] = React.useState<string>(filterValues.assignation)
  const [selectedBankruptcyTrusteeName, setSelectedBankruptcyTrusteeName] = React.useState<string>(
    filterValues.trusteeName,
  )
  const [selectedStatesIso, setSelectedStatesIso] = React.useState<string[]>(filterValues.statesIso)

  const { t } = useTranslation()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)
  const programsByCompanyId = getProgramsByCompanyId([selectedCompany] as EFinancingCompany[])
  const availableFinancingPrograms = buildSelectValueListFromEnumWithTranslation(
    financingProgramEnum,
    lang,
    programsByCompanyId,
    undefined,
    ['ifxpress', 'vlvr', 'products', 'auto-rsla'],
  )
  const reset = () => {
    resetFilter()
  }
  useEffect(() => {
    setSelectedLoanTypes(filterValues.financingProgramIds)
    setSelectedEntryTypes(filterValues.entryType)
    setSelectedLanguageId(filterValues.correspondanceLanguageId)
    setselectedAssignmentStatus(filterValues.assignation)
    setSelectedBankruptcyTrusteeName(filterValues.trusteeName)
    setSelectedStatesIso(filterValues.statesIso)
  }, [filterValues])

  return (
    <Grid container spacing={{ md: 2 }} sx={{ mt: 5 }} alignItems="center">
      <Grid item xs={12} sm={6} md={2}>
        <MultipleSelectCheckmarks
          title={t('editCreditApplication.financingProgramType')}
          content={availableFinancingPrograms}
          defaultValues={selectedLoanTypes}
          onValueChanged={(financingProgram: number[] | string[]) => {
            setSelectedLoanTypes(financingProgram as string[])
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MultipleSelectCheckmarks
          title={t('taskManager.taskType')}
          content={EEntryTypeList.map((value) => ({ label: t(`taskManager.${value}`), value }))}
          defaultValues={selectedEntryTypes}
          onValueChanged={(entryType: number[] | string[]) => {
            setSelectedEntryTypes(entryType as string[])
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <SelectComponent
          items={EAssignmentFilterList.map((value) => ({
            label: t(`enum.eAssignmentFilter.${value}`),
            value,
          }))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setselectedAssignmentStatus(event.target.value)
          }}
          label={t('taskManager.applicationAssignment') as string}
          value={selectedAssignmentStatus}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <SelectComponent
          items={Object.values(trusteeNames).map((value) => ({
            label: value,
            value,
          }))}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSelectedBankruptcyTrusteeName(e.target.value)
          }
          value={selectedBankruptcyTrusteeName}
          label={t('taskManager.bankruptcyTrusteeNames') as string}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2}>
        <MultipleSelectCheckmarks
          title={t('credit.languageId')}
          content={ELanguageList.map((value) => ({
            label: t(`enum.eLanguage.${value}`),
            value,
          }))}
          defaultValues={selectedLanguageId}
          onValueChanged={(languageIds: number[] | string[]) => {
            setSelectedLanguageId(languageIds as number[])
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2}>
        <MultipleSelectCheckmarks
          content={EProvinceList.map((value) => ({ label: value, value }))}
          onValueChanged={(e: string[] | number[]) => setSelectedStatesIso(e as string[])}
          defaultValues={selectedStatesIso}
          title="Province"
        />
      </Grid>
      <Grid item xs={12} sm={4} md={1}>
        <ButtonGroup>
          <Button variant="outlined" startIcon={<ClearIcon fontSize="small" color="error" />} onClick={reset}>
            {t('taskManager.reset')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<KeyboardArrowRightIcon fontSize="small" color="success" />}
            onClick={() =>
              applyFilter({
                ...filterValues,
                offset: 0,
                financingProgramIds: selectedLoanTypes,
                entryType: selectedEntryTypes,
                correspondanceLanguageId: selectedLanguageId,
                assignation: selectedAssignmentStatus,
                trusteeName: selectedBankruptcyTrusteeName,
                statesIso: selectedStatesIso,
              })
            }
          >
            {t('taskManager.apply')}
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

export default FilterComponent
