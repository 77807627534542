import availableCreditEffects from '@src/data/store/AvailableCredit/available-credit-effects'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import React from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ECreditApplicationStatus, EDecision, EFinancingProgram } from '@src/data/types'
import api from '../../data/api'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { creditActions, creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import { fullCreditApplicationEffects, setFullCreditApp } from '../../data/store/FullCreditApplication'
import { messageActions } from '../../data/store/Message'
import messageEffects from '../../data/store/Message/message-effects'
import CreditApplicationMessagePage from '../CreditApplicationMessagePage'
import CreditDecisionPage from '../CreditDecisionPage'
import EditAutoWorksheetPage from '../EditAutoWorksheetPage'
import EditCreditApplicationPage from '../EditCreditApplicationPage/EditCreditApplicationPage'
import SplitDocuments from '../SplitDocsPage'
import ViewCreditApplicationPage from '../ViewCreditApplicationPage'
import WorksheetDecisionPage from '../WorksheetDecisionPage'
import RouteGuard from './RouteGuard'
import PersonalLoanWorksheetViewPage from '../ViewWorksheet/PersonalLoanWorksheetViewPage'
import B2cWorksheetViewPage from '../ViewWorksheet/B2cWorksheetViewPage'
import EditB2cWorksheetPage from '../EditWorksheet/EditB2cWorksheetPage'
import EditPersonalLoanWorksheetPage from '../EditWorksheet/EditPersonalLoanWorksheetPage'

const LoadCreditApplication = () => {
  const dispatchEffect = useSideEffect()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const path = useLocation()
  const { id, financingProgramId } = useParams<{ id: string; financingProgramId: EFinancingProgram }>()
  const current = useAppSelector(creditSelectors.getCurrent)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const apiClient = useAppSelector(appSelectors.getApiClient)

  React.useEffect(() => {
    if (id && financingProgramId) {
      dispatch(messageActions.setMessageList([]))
      dispatchEffect(fullCreditApplicationEffects.getById(id, financingProgramId)).catch(() => {
        navigate('/notfound', { replace: true })
      })
      dispatchEffect(messageEffects.getMessagesForCreditApplicationId(id, financingProgramId)).catch(
        reportErrorToConsole,
      )
      dispatchEffect(creditEffects.getCreditApplicationLock({ financingProgramId, creditApplicationId: id })).catch(
        reportErrorToConsole,
      )
      dispatchEffect(availableCreditEffects.getAvailableCredit(id, financingProgramId)).catch(reportErrorToConsole)
    }
  }, [id, financingProgramId, dispatch, dispatchEffect, navigate])

  const pollPrequal = useAppSelector(creditSelectors.isPrequalificationPending)
  const pollFinalDecision = useAppSelector(creditSelectors.isFinalDecisionPending)

  React.useEffect(() => {
    let interval: number
    if (creditApplication && apiClient && (pollPrequal || pollFinalDecision)) {
      let timesRun = 0
      interval = window.setInterval(() => {
        if (timesRun < 5) {
          timesRun += 1
          api.fullCredit
            .getById(apiClient, { id: creditApplication.id, financingProgramId: creditApplication.financingProgramId })
            .then((ret) => {
              if (
                (pollPrequal && ret?.credit?.prequalificationDecision !== null) ||
                (pollFinalDecision && ret?.credit?.finalCreditDecision?.decision !== EDecision.pending)
              ) {
                setFullCreditApp(ret, dispatch)
              }
            })
            .catch(reportErrorToConsole)
        } else {
          window.clearInterval(interval)
        }
      }, 5000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [creditApplication, pollPrequal, pollFinalDecision, dispatch, apiClient])

  React.useEffect(() => {
    if (creditApplication?.id && creditApplication?.status !== ECreditApplicationStatus.Draft) {
      dispatch(creditActions.setApplicantPreviousApplications([]))
      dispatchEffect(
        creditEffects.getPreviousApplicantApplication({
          Sin: creditApplication.applicant.sin ?? '',
          FirstName: creditApplication.applicant.firstName ?? '',
          LastName: creditApplication.applicant.lastName ?? '',
          DateOfBirth: creditApplication.applicant.birthDate.toString() ?? '',
          EquifaxUniqueId: creditApplication.applicant.hardHitReport?.creditReportUniqueNumber ?? '',
          FinancingProgramId: creditApplication.financingProgramId,
        }),
      ).catch(reportErrorToConsole)
    }
  }, [
    creditApplication?.id,
    creditApplication?.status,
    creditApplication?.applicant.sin,
    creditApplication?.applicant.firstName,
    creditApplication?.applicant.lastName,
    creditApplication?.applicant.birthDate,
    creditApplication?.applicant.hardHitReport?.creditReportUniqueNumber,
    creditApplication?.financingProgramId,
    dispatch,
    dispatchEffect,
  ])

  React.useEffect(() => {
    if (creditApplication?.coApplicant?.firstName && creditApplication?.status !== ECreditApplicationStatus.Draft) {
      dispatch(creditActions.setCoapplicantPreviousApplications([]))
      dispatchEffect(
        creditEffects.getPreviousCoapplicantApplication({
          Sin: creditApplication.coApplicant.sin ?? '',
          FirstName: creditApplication.coApplicant.firstName ?? '',
          LastName: creditApplication.coApplicant.lastName ?? '',
          DateOfBirth: creditApplication.coApplicant.birthDate.toString() ?? '',
          EquifaxUniqueId: creditApplication.coApplicant.hardHitReport?.creditReportUniqueNumber ?? '',
          FinancingProgramId: creditApplication.financingProgramId,
        }),
      ).catch(reportErrorToConsole)
    }
  }, [
    creditApplication?.id,
    creditApplication?.status,
    creditApplication?.coApplicant?.sin,
    creditApplication?.coApplicant?.firstName,
    creditApplication?.coApplicant?.lastName,
    creditApplication?.coApplicant?.birthDate,
    creditApplication?.coApplicant?.hardHitReport?.creditReportUniqueNumber,
    creditApplication?.financingProgramId,
    dispatch,
    dispatchEffect,
  ])

  React.useEffect(() => {
    return function cleanup() {
      dispatch(creditActions.setApplicantPreviousApplications([]))
      dispatch(creditActions.setCoapplicantPreviousApplications([]))
      dispatch(messageActions.setMessageList([]))
      dispatch(creditActions.setCurrent(null))
      dispatch(creditActions.setMerchant(null))
      dispatch(creditActions.setIncomesHistory(null))
      dispatch(creditActions.setCreditApplicationLock(null))
      dispatch(creditActions.setDecisionHistory([]))
      dispatch(creditActions.setFlinksIncomes([]))
    }
  }, [dispatch])

  document.title = `#${current?.referenceNumber}`

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [path])

  return (
    <Routes>
      <Route path="view" element={<ViewCreditApplicationPage />} />
      <Route element={<RouteGuard requiredPermission="canEditCreditApp" />}>
        <Route path="edit" element={<EditCreditApplicationPage />} />
      </Route>
      <Route path="decision" element={<CreditDecisionPage />} />
      <Route path="messages" element={<CreditApplicationMessagePage />} />
      <Route path="split-documents" element={<SplitDocuments />} />
      {financingProgramId === EFinancingProgram.Auto && (
        <>
          <Route path="worksheet" element={<WorksheetDecisionPage />} />
          <Route path="worksheet/edit" element={<EditAutoWorksheetPage />} />
        </>
      )}

      {financingProgramId === EFinancingProgram.Personal && (
        <>
          <Route path="worksheet" element={<PersonalLoanWorksheetViewPage />} />
          <Route path="worksheet/edit" element={<EditPersonalLoanWorksheetPage />} />
        </>
      )}

      {financingProgramId === EFinancingProgram.B2c && (
        <>
          <Route path="worksheet" element={<B2cWorksheetViewPage />} />
          <Route path="worksheet/edit" element={<EditB2cWorksheetPage />} />
        </>
      )}
    </Routes>
  )
}

export default LoadCreditApplication
