import { useCallback, useMemo } from 'react'
import {
  addDays,
  addMonths,
  isAfter,
  isLastDayOfMonth,
  isValid,
  isWeekend,
  isWithinInterval,
  parse,
  setDate,
} from 'date-fns'
import { useTranslation } from 'react-i18next'
import { MerchantPaymentPlan } from '../../data/types/MerchantPaymentPlan'
import { CreditApplication, EPaymentPlan, EProvince } from '../../data/types'
import { formatDate, formatPaymentPlan, formatUtcIsoDateTime } from '../../services/Formatter'
import { FundingComputedDto } from '../../data/types/FundingComputedDto'

const availableDatesOptions = [1, 15, 23]
export const aprThresholds: Record<string, number> = {
  [EProvince.britishColombia]: 32,
  [EProvince.newfoundland]: 27,
}

export const usePaymentPlanList = (createdOn?: Date, paymentPlans?: MerchantPaymentPlan[]) => {
  const { t } = useTranslation()

  const ret = useMemo(() => {
    const selectValueList: { label: string; value: string }[] = []

    selectValueList.push({
      value: EPaymentPlan.regularDailyInterests,
      label: t('enum.ePaymentPlan.regularInterest'),
    })

    const currentDate = new Date()
    const creationDate = createdOn ? new Date(createdOn) : null
    const DISTANT_FUTURE = new Date('2099-12-31T23:59:59.999Z')

    const filteredPaymentPlans = paymentPlans?.filter((plan) => {
      const planActiveFrom = new Date(plan.activeFrom)
      const planExpiresOn = new Date(plan?.expiresOn ?? DISTANT_FUTURE)
      const currentUtcDateFormatted = new Date(formatUtcIsoDateTime(new Date()))
      return (
        ((creationDate && isWithinInterval(creationDate, { start: planActiveFrom, end: planExpiresOn })) ||
          isWithinInterval(currentDate, { start: planActiveFrom, end: planExpiresOn })) &&
        isAfter(planExpiresOn, currentUtcDateFormatted)
      )
    })

    filteredPaymentPlans?.forEach((p) => {
      selectValueList.push({ value: p.id, label: formatPaymentPlan(p) })
    })

    return selectValueList
  }, [createdOn, paymentPlans, t])

  return ret
}

export const usePossibleTerms = (maxTermDuration: number | null | undefined) => {
  const ret = useMemo(() => {
    const supportedTerms = [12, 18, 24, 36, 48, 60, 72]
    return supportedTerms.filter((val) => val <= (maxTermDuration ?? 0))
  }, [maxTermDuration])

  return ret
}

export const useIsNotEndOfMonthAndWeekday = () => {
  const ret = useCallback((date: Date) => isWeekend(date) && !isLastDayOfMonth(date), [])
  return ret
}

export const useIsHoliday = (listHolidays: Date[]) => {
  const ret = useCallback(
    (date: Date) => {
      const formattedDate = formatDate(date)
      return listHolidays.toString().includes(formattedDate)
    },
    [listHolidays],
  )
  return ret
}

export const useCantSelectDate = (listHolidays: Date[]) => {
  const isHoliday = useIsHoliday(listHolidays)
  const isNotEndOfMonthAndWeekday = useIsNotEndOfMonthAndWeekday()

  const cantSelectDate = useCallback(
    (date: Date) => {
      return isHoliday(date) || isNotEndOfMonthAndWeekday(date)
    },
    [isHoliday, isNotEndOfMonthAndWeekday],
  )

  return cantSelectDate
}

export const useNthBusinessDayAfterDate = (listHolidays: Date[]) => {
  const cantSelectDate = useCantSelectDate(listHolidays)

  const nthBusinessDayAfterDate = useCallback(
    (startDate: Date, nthBusinessDay: number) => {
      let n = nthBusinessDay
      for (let i = 1; i <= n; i += 1) {
        const date = addDays(startDate, i)
        if (cantSelectDate(date)) n += 1
      }
      return addDays(startDate, n)
    },
    [cantSelectDate],
  )

  return nthBusinessDayAfterDate
}

export const useEarliestPaymentDate = (listHolidays: Date[]) => {
  const nthBusinessDayAfterDate = useNthBusinessDayAfterDate(listHolidays)

  const getEarliestPaymentDateGivenActivationDate = useCallback(
    (activationDate: Date) => {
      const initialEarliestDateForFirstPayment = nthBusinessDayAfterDate(activationDate, 5)
      let earliestDayForFirstPayment = initialEarliestDateForFirstPayment.getDate()

      earliestDayForFirstPayment = availableDatesOptions.find((day) => day >= earliestDayForFirstPayment) ?? 0

      if (earliestDayForFirstPayment > 0) {
        return new Date(
          initialEarliestDateForFirstPayment.getFullYear(),
          initialEarliestDateForFirstPayment.getMonth(),
          earliestDayForFirstPayment,
        )
      }

      return new Date(
        initialEarliestDateForFirstPayment.getFullYear(),
        initialEarliestDateForFirstPayment.getMonth() + 1,
        availableDatesOptions[0],
      )
    },
    [nthBusinessDayAfterDate],
  )

  return getEarliestPaymentDateGivenActivationDate
}

const rotateListFromGivenValue = (listToRotate: Array<number>, rotateFrom: number) => {
  const index = listToRotate.indexOf(rotateFrom)
  return listToRotate.slice(index).concat(listToRotate.slice(0, index))
}

export const useFirstPaymentDateOptions = (listHolidays: Date[]) => {
  const getEarliestPaymentDateGivenActivationDate = useEarliestPaymentDate(listHolidays)

  const computeFirstPaymentDateOptions = useCallback(
    (activationDate: string) => {
      const activationDateAsDate = parse(activationDate, 'yyyy-MM-dd', new Date())
      const earliestPaymentDate = getEarliestPaymentDateGivenActivationDate(activationDateAsDate)
      const earliestPaymentDay = earliestPaymentDate.getDate()
      const rotatedListOfOptions = rotateListFromGivenValue(availableDatesOptions, earliestPaymentDay)
      rotatedListOfOptions.push(rotatedListOfOptions[0])
      const firstPaymentDateOptionsList: Array<Date> = []

      rotatedListOfOptions.forEach((day) => {
        let firstPaymentDate = setDate(earliestPaymentDate, day)
        firstPaymentDate = firstPaymentDateOptionsList.some((date) => date.getTime() > firstPaymentDate.getTime())
          ? addMonths(firstPaymentDate, 1)
          : firstPaymentDate
        firstPaymentDateOptionsList.push(firstPaymentDate)
      })
      return firstPaymentDateOptionsList.filter((option) => isValid(option))
    },
    [getEarliestPaymentDateGivenActivationDate],
  )

  return computeFirstPaymentDateOptions
}

export const useAprTooHigh = (computedInfo: FundingComputedDto, creditApplication: CreditApplication | null) => {
  const aprTooHigh = useMemo(() => {
    const address = creditApplication?.applicant.currentAddress
    if (!address || !computedInfo) return false

    const { effectiveRate } = computedInfo
    const { stateIso } = address

    const threshold = aprThresholds[stateIso!]

    return threshold !== undefined && effectiveRate >= threshold
  }, [computedInfo, creditApplication?.applicant.currentAddress])

  return aprTooHigh
}
