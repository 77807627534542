import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Button, Grid, IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InputTextField } from '../../../components'
import { AutoWorksheet, CreditApplication } from '../../../data/types'
import { Merchant } from '../../../data/types/Merchant'
import CopyMessage from '../../../services/strings'

type Props = {
  worksheet: AutoWorksheet
  creditApplication: CreditApplication
  merchant: Merchant | null
  onSubmit: () => void
  onSaveDraft: () => void
}

const ProgramSectionEdit = ({
  worksheet,
  creditApplication,
  merchant,
  onSubmit,
  onSaveDraft,
}: Props): JSX.Element | null => {
  const { t } = useTranslation()
  const plan = worksheet.brokerRequestedFinancingPlan ?? creditApplication.finalCreditDecision.planName

  return (
    <Grid container item spacing={2} sx={{ display: 'flex', flexDirection: 'row', mt: 3, p: 2 }}>
      <Grid item xs={6} md={2.7} sx={{ flexDirection: 'row' }}>
        <InputTextField label={t('worksheet.program')} disabled value={plan ? t(`credit.plans.${plan}`) : ''} />
      </Grid>
      <Grid item xs={6} md={2.7} sx={{ flexDirection: 'row' }}>
        <InputTextField
          label="Province"
          disabled
          value={merchant?.province ? t(`enum.eProvince.${merchant?.province}`) : ''}
        />
      </Grid>
      <Grid item xs={6} md={2.7} sx={{ flexDirection: 'row' }}>
        <InputTextField
          label={t('editCreditApplication.address.postalCode')}
          value={merchant?.zipCode}
          disabled
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => merchant?.zipCode && CopyMessage(merchant?.zipCode)}>
                <CopyAllIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6} md={3.9} sx={{ flexDirection: 'row' }}>
        <Stack direction="row" justifyContent="flex-end" spacing={3}>
          <Button variant="contained" color="secondary" onClick={onSaveDraft}>
            {t('worksheet.saveDraft')}
          </Button>
          <Button variant="contained" color="primary" type="submit" value="finale" onClick={onSubmit}>
            {t('worksheet.submit')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ProgramSectionEdit
