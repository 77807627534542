import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { EFinancingProgram } from '@src/data/types'
import { TRootState, useAppSelector } from '../../../data/store'
import { PageSpinner } from '../../../components'
import { creditEffects, creditSelectors } from '../../../data/store/CreditApplication'
import { CreditDecisionHistory } from '../../../data/types/IncomesHistory'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { formatDateTime } from '../../../services/Formatter'

type RowProps = {
  decisionRevisions: CreditDecisionHistory
  oldDecisionRevision: CreditDecisionHistory
  financingProgramId: EFinancingProgram
}

const CreditDecisionHistoryHistoryRow = ({ decisionRevisions, oldDecisionRevision, financingProgramId }: RowProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const GetColorAfterComparingValues = (
    newValue: string | undefined | number | null,
    oldValue: string | undefined | number | null,
  ) => {
    if (newValue === oldValue)
      return theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
    return theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light
  }

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const planEnum = useAppSelector((state: TRootState) => appSelectors.getPlanEnumForProgram(state, financingProgramId))

  return (
    <TableRow>
      <TableCell align="center">{decisionRevisions.applicantFullName}</TableCell>
      <TableCell align="center">{decisionRevisions.coApplicantFullName}</TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            decisionRevisions.finalCreditDecision?.planName,
            oldDecisionRevision.finalCreditDecision?.planName,
          ),
        }}
      >
        {decisionRevisions.finalCreditDecision?.planName != null && planEnum != null
          ? planEnum[decisionRevisions.finalCreditDecision?.planName ?? ''][lang]
          : 'N/A'}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            decisionRevisions.finalCreditDecision?.interestRate,
            oldDecisionRevision.finalCreditDecision?.interestRate,
          ),
        }}
      >
        {decisionRevisions.finalCreditDecision?.interestRate} %
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            decisionRevisions.finalCreditDecision?.maxAmountFinanced,
            oldDecisionRevision.finalCreditDecision?.maxAmountFinanced,
          ),
        }}
      >
        {decisionRevisions.finalCreditDecision?.maxAmountFinanced}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            decisionRevisions.finalCreditDecision?.maxPmtAmount,
            oldDecisionRevision.finalCreditDecision?.maxPmtAmount,
          ),
        }}
      >
        {decisionRevisions.finalCreditDecision?.maxPmtAmount}
      </TableCell>
      <TableCell align="center">{formatDateTime(decisionRevisions.finalCreditDecision?.madeOn)}</TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            decisionRevisions.finalCreditDecision?.decision,
            oldDecisionRevision.finalCreditDecision?.decision,
          ),
        }}
      >
        {decisionRevisions.finalCreditDecision?.decision
          ? t(`credit.${decisionRevisions.finalCreditDecision?.decision}`)
          : ''}
      </TableCell>
      <TableCell align="center">
        {decisionRevisions.finalCreditDecision?.decidorId
          ? decisionRevisions.finalCreditDecision?.decidorFullName
          : t('common.system')}
      </TableCell>
    </TableRow>
  )
}

type TableProps = {
  decisionRevisions: CreditDecisionHistory[]
  financingProgramId: EFinancingProgram
}

const CreditDecisionHistoryTable = ({ decisionRevisions, financingProgramId }: TableProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
      <TableContainer sx={{ mt: 2, maxHeight: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('common.applicant')}</TableCell>
              <TableCell align="center">{t('common.coApplicant')}</TableCell>
              <TableCell align="center">{t('common.program')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.rate')}</TableCell>
              <TableCell align="center">{t('common.loan')}</TableCell>
              <TableCell align="center">{t('credit.maxPayment')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.DateTime')}</TableCell>
              <TableCell align="center">{t('credit.decision')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.user')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {decisionRevisions.length !== 0 &&
              decisionRevisions.map((decisionRevision, index, decisionRevisionsArray) => (
                <CreditDecisionHistoryHistoryRow
                  decisionRevisions={decisionRevision}
                  oldDecisionRevision={
                    decisionRevisionsArray[index + 1 === decisionRevisionsArray.length ? index : index + 1]
                  }
                  key={decisionRevision.finalCreditDecision?.madeOn}
                  financingProgramId={financingProgramId}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

type Props = {
  onClose: () => void
  open: boolean
  creditApplicationId: string
  financingProgramId: EFinancingProgram
}

const CreditDecisionHistoryDialog = ({ onClose, open, creditApplicationId, financingProgramId }: Props) => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (open && creditApplicationId) {
      setLoading(true)
      dispatchEffect(creditEffects.getCreditDecisionHistory(creditApplicationId, financingProgramId))
        .finally(() => {
          setLoading(false)
        })
        .catch(reportErrorToConsole)
    }
  }, [creditApplicationId, financingProgramId, open, dispatchEffect])

  const decisionHistory = useAppSelector(creditSelectors.getDecisionHistory)

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('history.decision')}
      </DialogTitle>
      <DialogContent>
        <PageSpinner isLoading={isLoading} />
        <CreditDecisionHistoryTable decisionRevisions={decisionHistory} financingProgramId={financingProgramId} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CreditDecisionHistoryDialog)
