import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
} from '@mui/material'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

type Props = { onClose: () => void; open: boolean; onSubmit: () => void }

const SaveDialog = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [firstConditionChecked, setFirstConditionChecked] = React.useState(false)
  const [secondConditionChecked, setSecondConditionChecked] = React.useState(false)
  const [thirdConditionChecked, setThirdConditionChecked] = React.useState(false)

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstConditionChecked(event.target.checked)
  }

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondConditionChecked(event.target.checked)
  }

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThirdConditionChecked(event.target.checked)
  }

  const setFalse = () => {
    setFirstConditionChecked(false)
    setSecondConditionChecked(false)
    setThirdConditionChecked(false)
  }

  const translatedLink = (
    <Trans
      i18nKey="submission.iHaveShownPrivacyPolicyToCustomer"
      components={{
        policyLink: (
          <Link rel="noreferrer" href={`${t('submission.privacyPolicyLink')}`} target="_blank">
            {t('submission.privacyPolicy')}
          </Link>
        ),
      }}
    />
  )

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xl">
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('submission.submission')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span" sx={{ mt: 5 }} id="alert-dialog-description">
          <Stack>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleChange1} />}
                label={<span style={{ whiteSpace: 'pre-line' }}>{`${t('submission.iHaveInformedTheCustomer')}`}</span>}
              />
            </FormGroup>
            <ol className="span" style={{ margin: 0, marginLeft: '0.4em' }} type="i">
              <li>{t('submission.contentIsCorrect')}</li>
              <li>{t('submission.creditCheckSent')}</li>
              <li>{t('submission.createTemporaryNote')}</li>
              <li>{t('submission.creditReportSignedKept')}</li>
            </ol>
            <Box
              sx={{
                height: 1,
              }}
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleChange2} />}
                label={<span style={{ whiteSpace: 'pre-line' }}>{translatedLink}</span>}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleChange3} />}
                label={<span style={{ whiteSpace: 'pre-line' }}>{t('submission.iConfirm')}</span>}
              />
            </FormGroup>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={() => {
              setFalse()
              onClose()
            }}
          >
            {t('submission.cancel')}
          </Button>
        </Box>
        <Box sx={{ flex: '1 1 auto' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!(firstConditionChecked && secondConditionChecked && thirdConditionChecked)}
            onClick={() => {
              setFalse()
              onSubmit()
            }}
          >
            {t('submission.submit')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(SaveDialog)
