import React from 'react'
import { Box, Toolbar, Chip, Badge } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StatusCount } from '../../../data/types'

type Props = {
  statusCount: StatusCount | null
}

const CountStatuses = ({ statusCount }: Props) => {
  const { t } = useTranslation()

  return (
    <Toolbar sx={{ ml: '10%', justifyContent: 'space-between', mr: '10%' }}>
      <Badge badgeContent={statusCount?.creditCount.toString() ?? '0'} color="primary" max={10000}>
        <Chip label={t('statusCounts.credit')} sx={{ minWidth: 200 }} variant="outlined" />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge badgeContent={statusCount?.documentsAndIncomesCount.toString() ?? '0'} color="primary" max={10000}>
        <Chip label={t('statusCounts.documentsAndIncomes')} sx={{ minWidth: 200 }} variant="outlined" />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge badgeContent={statusCount?.financingCount.toString() ?? '0'} color="primary" max={10000}>
        <Chip label={t('statusCounts.financing')} sx={{ minWidth: 200 }} variant="outlined" />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge badgeContent={statusCount?.fundingCount.toString() ?? '0'} color="primary" max={10000}>
        <Chip label={t('statusCounts.funding')} sx={{ minWidth: 200 }} variant="outlined" />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge badgeContent={statusCount?.total.toString() ?? '0'} color="primary" max={100000}>
        <Chip label={t('statusCounts.total')} sx={{ minWidth: 200 }} />
      </Badge>
    </Toolbar>
  )
}

export default CountStatuses
