import React, { useMemo } from 'react'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { SelectComponent, InputTextField, DateTimePicker, PageError } from '../../../components'
import {
  CreditApplicationTask,
  creditTaskSchema,
  ECreditApplicationStepList,
  EFinancingProgram,
  SelectValueListItem,
} from '../../../data/types'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import ActionsDialog from '../../../components/ActionsDialog'
import { reportErrorToConsole } from '../../../services/error-logger'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

type Props = {
  open: boolean
  title: string
  defaultValue: Partial<CreditApplicationTask>
  onConfirm: (data: CreditApplicationTask) => void
  onCancel: () => void
}

const EditTaskDialog = ({ defaultValue, onConfirm, onCancel, open, title }: Props) => {
  const { t } = useTranslation()
  const error = useAppSelector(appSelectors.getBusinessError)
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const allTaskTypes = useAppSelector((state: TRootState) => appSelectors.getAllTaskTypes(state, financingProgramId!))
  const taskTypesAddableManually = useAppSelector((state: TRootState) =>
    appSelectors.getTaskTypesAddableManually(state, financingProgramId!),
  )

  const [selectContent, setSelectContent] = React.useState<Array<SelectValueListItem>>([])

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreditApplicationTask>({
    mode: 'onBlur',
    defaultValues: defaultValue,
    resolver: yupResolver(creditTaskSchema),
  })

  const buildSelectContent = useMemo(() => {
    return Object.values(taskTypesAddableManually)
      .filter((item) => taskTypesAddableManually[item.id])
      .map((item) => ({
        label: allTaskTypes[item.id][lang],
        value: item.id,
      }))
  }, [taskTypesAddableManually, allTaskTypes, lang])

  React.useEffect(() => {
    if (defaultValue.id && Object.values(taskTypesAddableManually).every((x) => x.id !== defaultValue.typeId))
      setSelectContent([{ label: allTaskTypes[defaultValue.typeId!][lang] as string, value: defaultValue.typeId! }])
    else setSelectContent(buildSelectContent)

    reset(defaultValue)
  }, [defaultValue, allTaskTypes, buildSelectContent, lang, reset, taskTypesAddableManually])

  const onSubmit = (data: CreditApplicationTask) => {
    onConfirm(data)
  }

  return (
    <ActionsDialog
      title={title}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit, reportErrorToConsole)}
      open={open}
    >
      <PageError errors={error} />

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={4}>
          <SelectComponent items={selectContent} label="Type" {...register('typeId')} error={errors?.typeId} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectComponent
            items={ECreditApplicationStepList.map((item) => ({
              label: `enum.eCreditApplicationSteps.${item}`,
              value: item,
            }))}
            label={t('common.requiredFor') as string}
            {...register('requiredBeforeStep')}
            error={errors?.requiredBeforeStep}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateTimePicker
            name="reminderOn"
            control={control}
            error={errors?.reminderOn}
            label={t('tasks.reminder')}
            disablePast={defaultValue.id === null}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextField label="Description" {...register('description')} error={errors?.description} />
        </Grid>
      </Grid>
    </ActionsDialog>
  )
}
export default React.memo(EditTaskDialog)
