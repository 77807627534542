import React from 'react'
import { Link, Typography, IconButton } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import { formatPhone } from '../../../services/Formatter'

interface PhoneNumberProps {
  phone: string
  phoneExt?: string
  applyPhoneFormatting: boolean
}

const PhoneNumber = ({ phone, phoneExt = '', applyPhoneFormatting }: PhoneNumberProps) => {
  const formattedPhone = applyPhoneFormatting ? formatPhone(phone) : phone
  const telephoneLink = phoneExt ? `tel:${formattedPhone},${phoneExt}` : `tel:${formattedPhone}`

  return (
    <Typography>
      <IconButton style={{ paddingLeft: '0' }}>
        <PhoneIcon fontSize="small" />
      </IconButton>
      {' : '}
      <Link href={telephoneLink}>
        {formattedPhone} {phoneExt && `ext.${phoneExt}`}
      </Link>
    </Typography>
  )
}

export default PhoneNumber
