import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import apiStateReducer from './ApiStateStore'
import appReducer, { appActions } from './AppStore/app-store'
import worksheetReducer from './AutoWorksheet/auto-worksheet-store'
import availableCreditReducer from './AvailableCredit/available-credit-store'
import b2cWorksheetReducer from './B2cWorksheet/b2c-worksheet-store'
import creditReducer from './CreditApplication/credit-application-store'
import creditDashBoardEntryReducer from './CreditDashboardEntry/credit-dashboard-entry-store'
import creditTaskDashBoardEntryReducer from './CreditTaskDashboardEntry/credit-task-dashboard-entry-store'
import documentReducer from './Document/document-store'
import electronicSignatureReducer from './ElectronicSignature/electronic-signature-store'
import messageReducer from './Message/message-store'
import personalLoanWorksheetReducer from './PersonalLoanWorksheet/personal-loan-worksheet-store'
import reportReducer from './report/report-store'
import requiredExternalStepReducer from './RequiredExternalStep/required-external-step-store'
import userReducer from './UserStore/user-store'
import vehicleReducer from './Vehicle/vehicle-store'
import { setReportVersionDeprecated } from '../api/make-api'

function InitializeStore() {
  const str = configureStore({
    reducer: {
      app: appReducer,
      user: userReducer,
      credit: creditReducer,
      creditTaskDashBoardEntry: creditTaskDashBoardEntryReducer,
      creditDashBoardEntry: creditDashBoardEntryReducer,
      api: apiStateReducer,
      worksheet: worksheetReducer,
      vehicle: vehicleReducer,
      document: documentReducer,
      message: messageReducer,
      requiredExternalStep: requiredExternalStepReducer,
      personalLoanWorksheet: personalLoanWorksheetReducer,
      b2cWorksheet: b2cWorksheetReducer,
      electronicSignature: electronicSignatureReducer,
      report: reportReducer,
      availableCredit: availableCreditReducer,
    },
  })
  setReportVersionDeprecated(() => str.dispatch(appActions.setDeprecated(true)))
  return str
}

export const store = InitializeStore()

export type TRootState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<TAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector
