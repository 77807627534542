import React, { useCallback } from 'react'
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { History as HistoryIcon, Gavel as GavelIcon } from '@mui/icons-material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { CreditApplication, EFinancingProgram } from '../../../data/types'
import CreditDecisionDialog from './creditDecisionDialog'
import { FinalCreditDecision } from '../../../data/types/CreditDecisionSchema'
import { TRootState, useAppSelector } from '../../../data/store'
import { creditEffects, creditSelectors } from '../../../data/store/CreditApplication'
import DecisonIcon from './decisonIcon'
import { FormatCurrencyNoDecimals } from '../../../services/Formatter'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import FormattedMerchantPaymentPlan from '../../ViewCreditApplicationPage/components/FormattedMerchantPaymentPlan'
import { fullCreditApplicationEffects } from '../../../data/store/FullCreditApplication'

type Props = {
  creditApplication: CreditApplication
  canApprove: boolean
  userCanApproveCreditApplication: boolean
  financingProgramId: EFinancingProgram
  editDisabled: boolean
  openHistoryDialog: () => void
}

const CreditDecisionIceberg = ({
  creditApplication,
  canApprove,
  userCanApproveCreditApplication,
  financingProgramId,
  editDisabled,
  openHistoryDialog,
}: Props) => {
  const [openIceberDecisionDialog, setOpenIceberDecisionDialog] = React.useState<boolean>(false)

  const { t } = useTranslation()

  const planEnum = useAppSelector((state: TRootState) =>
    appSelectors.getPlanEnumForProgram(state, creditApplication.financingProgramId),
  )
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const merchantPaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)

  const dispatchEffect = useSideEffect()
  const theme = useTheme()

  const handleSaveDecison = useCallback(
    (value: FinalCreditDecision) => {
      setOpenIceberDecisionDialog(false)
      value.versionTag = creditApplication.versionTag
      value.financingProgramId = creditApplication.financingProgramId
      return dispatchEffect(creditEffects.saveCreditDecision(value)).then(() =>
        dispatchEffect(
          fullCreditApplicationEffects.getById(creditApplication.id, creditApplication.financingProgramId),
        ),
      )
    },
    [creditApplication, dispatchEffect],
  )

  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        alignSelf="center"
      >
        <Typography variant="h6" component="h3" gutterBottom>
          {t('credit.finalDecision')}
        </Typography>
        <IconButton onClick={openHistoryDialog}>
          <HistoryIcon fontSize="large" color="primary" />
        </IconButton>
      </Stack>

      <DecisonIcon decision={creditApplication.finalCreditDecision.decision} />
      {merchantPaymentPlan ? (
        <Stack justifyContent="center" direction="row" sx={{ backgroundColor: theme.palette.action.selected }}>
          <Typography style={{ marginRight: '1rem' }}>Promo:</Typography>
          <FormattedMerchantPaymentPlan merchantPaymentPlan={merchantPaymentPlan} />
        </Stack>
      ) : (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {t('credit.interestRate')}: {creditApplication.finalCreditDecision.interestRate}%
        </Typography>
      )}

      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.plan')}:{' '}
        {creditApplication.finalCreditDecision.planName != null
          ? planEnum[creditApplication.finalCreditDecision.planName ?? ''][lang]
          : 'N/A'}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.authorizedLoanAmount')}: ${creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.maxPayment')}: {FormatCurrencyNoDecimals(creditApplication.finalCreditDecision.maxPmtAmount)}
      </Typography>
      <Button
        id="btnEditCreditDecision"
        variant="contained"
        startIcon={<GavelIcon />}
        size="large"
        sx={{ mt: 2 }}
        onClick={() => setOpenIceberDecisionDialog(true)}
        disabled={editDisabled || !userCanApproveCreditApplication}
      >
        {t('credit.decision')}
      </Button>
      <CreditDecisionDialog
        open={openIceberDecisionDialog}
        canApprove={canApprove}
        financingProgramId={financingProgramId}
        onCancel={() => setOpenIceberDecisionDialog(false)}
        onConfirm={handleSaveDecison}
        creditApplication={creditApplication}
      />
    </div>
  )
}

export default CreditDecisionIceberg
