import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker, InputTextField, SelectComponent } from '../../../components'
import {
  ApplicableTax,
  AutoWorksheet,
  CreditApplication,
  EPaymentFrequencyList,
  SelectValueListItem,
} from '../../../data/types'
import { formatDate } from '../../../services/Formatter'

interface Props {
  control: Control<AutoWorksheet>
  currentCreditApplication: CreditApplication
  applicableTaxOnInsurances: ApplicableTax | null
  setValue: UseFormSetValue<AutoWorksheet>
  worksheet: AutoWorksheet
  reset: UseFormReset<AutoWorksheet>
  register: UseFormRegister<AutoWorksheet>
  errors: Merge<FieldError, FieldErrorsImpl<AutoWorksheet>> | undefined
  maxAdmissibleTerm: number
  creditApplicationInterestRate: number
  watch: UseFormWatch<AutoWorksheet>
}

const TransactionForm = ({
  control,
  applicableTaxOnInsurances,
  setValue,
  worksheet,
  reset,
  register,
  errors,
  maxAdmissibleTerm,
  creditApplicationInterestRate,
  watch,
}: Props) => {
  const { t } = useTranslation()

  const priceWatch = watch('price')

  const accidentAndHealthInsuranceWatch = watch('accidentAndHealthInsurance')

  const extendedServiceWatch = watch('extendedService')

  const gapInsuranceWatch = watch('gapInsurance')

  const lifeInsuranceWatch = watch('lifeInsurance')

  const taxesOnInsuranceWatch = watch('taxesOnInsurance')

  const isTaxExemptWatch = watch('isTaxExempt')

  React.useEffect(() => {
    const taxableInsurance: number =
      Number(accidentAndHealthInsuranceWatch ?? 0) + Number(lifeInsuranceWatch ?? 0) + Number(gapInsuranceWatch ?? 0)
    if (isTaxExemptWatch === true) setValue('taxesOnInsurance', 0)
    else if (applicableTaxOnInsurances && taxableInsurance >= 0) {
      let taxes = 0
      if (applicableTaxOnInsurances.percentage) taxes = (applicableTaxOnInsurances.percentage / 100) * taxableInsurance
      setValue('taxesOnInsurance', Number(taxes.toFixed(2)))
    }
  }, [
    gapInsuranceWatch,
    lifeInsuranceWatch,
    accidentAndHealthInsuranceWatch,
    isTaxExemptWatch,
    setValue,
    applicableTaxOnInsurances,
  ])

  React.useEffect(() => {
    let afterMarketPercent = 0

    if (priceWatch > 0) {
      afterMarketPercent =
        ((Number(accidentAndHealthInsuranceWatch ?? 0) +
          Number(extendedServiceWatch ?? 0) +
          Number(gapInsuranceWatch ?? 0) +
          Number(lifeInsuranceWatch ?? 0) +
          Number(taxesOnInsuranceWatch ?? 0)) /
          Number(priceWatch)) *
        100
    }
    setValue('aftermarketVsValue', Number(afterMarketPercent.toFixed(2)))
  }, [
    accidentAndHealthInsuranceWatch,
    extendedServiceWatch,
    gapInsuranceWatch,
    lifeInsuranceWatch,
    taxesOnInsuranceWatch,
    priceWatch,
    setValue,
  ])

  React.useEffect(() => {
    const today = formatDate(new Date())
    if (worksheet) {
      reset({
        ...worksheet,
        deliveryOn: formatDate(worksheet.deliveryOn) < today ? today : worksheet.deliveryOn,
      })
    }
  }, [worksheet, reset])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const term = [...Array(13)].map((_, i) => 6 * (i + 1) + 6)
  const extendedServiceDuration: SelectValueListItem[] = [
    { value: 0, label: '   ' },
    { value: 12, label: `12 ${t('worksheet.month')}` },
    { value: 24, label: `24 ${t('worksheet.month')}` },
    { value: 36, label: `36 ${t('worksheet.month')}` },
  ]
  const gapInsuranceDuration: SelectValueListItem[] = [
    { value: -1, label: '   ' },
    { value: 0, label: `1 ${t('worksheet.to')} 59 ${t('worksheet.month')}` },
    { value: 1, label: `60 ${t('worksheet.month')} ${t('worksheet.andMore')}` },
  ]

  return (
    <>
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h4" component="span" gutterBottom>
          Transaction
        </Typography>
      </Divider>

      <FormControlLabel
        {...register('isTaxExempt')}
        control={<Checkbox defaultChecked={worksheet?.isTaxExempt} />}
        label={t('worksheet.taxExempt')}
        sx={{ m: -1, p: 2 }}
      />

      <Grid container item xs={12} spacing={3} sx={{ mt: 1, p: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField label={t('worksheet.price')} error={errors?.price} {...register('price')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField error={errors?.rebate} {...register('rebate')} label={t('worksheet.rebate')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField error={errors?.cashDown} {...register('cashDown')} label={t('worksheet.cashDown')} />
        </Grid>
        <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
          <DatePicker
            name="deliveryOn"
            control={control}
            label={t('worksheet.deliveryOn')}
            error={errors?.deliveryOn}
            disablePast
          />
        </Grid>
      </Grid>
      <Typography variant="h6" component="div" gutterBottom sx={{ paddingLeft: 2 }}>
        {t('worksheet.feesAndAccessories')}
      </Typography>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={2.4}>
          <InputTextField
            error={errors?.installationDelivery}
            {...register('installationDelivery')}
            label={t('worksheet.installationAndDelivery')}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <InputTextField error={errors?.licenseFee} {...register('licenseFee')} label={t('worksheet.licenseFee')} />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <InputTextField
            error={errors?.dealerAdminFee}
            {...register('dealerAdminFee')}
            label={t('worksheet.dealerAdminFee')}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <InputTextField
            error={errors?.dealerAdminFee}
            {...register('icebergLenderAdminFee')}
            disabled
            label={t('worksheet.lenderAdminFee')}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <InputTextField
            error={errors?.licenseFee}
            {...register('icebergPPSA')}
            disabled
            label={t('worksheet.ppsa')}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.otherTaxable}
            {...register('otherTaxable')}
            label={t('worksheet.otherTaxable')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.otherTaxableDescription}
            {...register('otherTaxableDescription')}
            label={t('worksheet.otherTaxableDescription')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.otherNonTaxable}
            {...register('otherNonTaxable')}
            label={t('worksheet.otherNonTaxable')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.otherNonTaxableDescription}
            {...register('otherNonTaxableDescription')}
            label={t('worksheet.otherNonTaxableDescription')}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" component="div" sx={{ paddingLeft: 2 }} gutterBottom>
        {t('worksheet.aftermarketService')}
      </Typography>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.extendedService}
            {...register('extendedService')}
            label={t('worksheet.extendedService')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={extendedServiceDuration}
            values={extendedServiceDuration}
            label={t('worksheet.extendedServiceDuration') as string}
            {...register('extendedServiceDuration')}
            error={errors?.extendedServiceDuration}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.gapInsurance}
            {...register('gapInsurance')}
            label={t('worksheet.gapInsurance')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={gapInsuranceDuration}
            values={gapInsuranceDuration}
            error={errors?.gapInsuranceDuration}
            {...register('gapInsuranceDuration')}
            label={t('worksheet.gapInsuranceDuration') as string}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.lifeInsurance}
            {...register('lifeInsurance')}
            label={t('worksheet.lifeInsurance')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.accidentAndHealthInsurance}
            {...register('accidentAndHealthInsurance')}
            label={t('worksheet.accidentAndHealthInsurance')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.taxesOnInsurance}
            {...register('taxesOnInsurance')}
            label={t('worksheet.taxesOnInsurance')}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.aftermarketVsValue}
            {...register('aftermarketVsValue')}
            label={t('worksheet.aftermarketVsValue')}
            disabled
          />
        </Grid>
      </Grid>

      <Typography variant="h6" component="div" sx={{ paddingLeft: 2 }} gutterBottom>
        {t('worksheet.funding')}
      </Typography>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={term.map((item) => ({
              label:
                item <= maxAdmissibleTerm ? `${item} ${t('worksheet.month')}` : `** ${item} ${t('worksheet.month')} **`,
              value: item,
            }))}
            label={t('worksheet.term') as string}
            {...register('term')}
            error={errors?.term}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EPaymentFrequencyList.map((item) => {
              const data: SelectValueListItem = { label: `worksheet.${item}`, value: item }
              return data
            })}
            label={t('worksheet.paymentFrequency') as string}
            {...register('paymentFrequency')}
            error={errors?.paymentFrequency}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField value={creditApplicationInterestRate} label={t('worksheet.interestRate')} disabled />
        </Grid>

        <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
          <DatePicker
            name="firstPaymentOn"
            control={control}
            label={t('worksheet.firstPaymentOn')}
            error={errors?.firstPaymentOn}
            disablePast
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TransactionForm
