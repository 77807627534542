import { Grid, Stack, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { worksheetEffects } from '@src/data/store/AutoWorksheet'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Breadcrumb, PageError, PageSpinner } from '../../components'
import PendingMessage from '../../components/PendingMessage'
import { useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { autoWorksheetSelectors } from '../../data/store/AutoWorksheet/auto-worksheet-store'
import { creditSelectors } from '../../data/store/CreditApplication'
import { documentEffects, documentSelectors } from '../../data/store/Document'
import { messageEffects, messageSelectors } from '../../data/store/Message'
import { userSelectors } from '../../data/store/UserStore'
import { vehicleEffects } from '../../data/store/Vehicle'
import {
  AutoWorksheetDecisionDTO,
  BaseWorksheetFundingThresholdExtend,
  CreditApplicationMessage,
  EDocumentStatus,
  EDocumentType,
  EFinancingProgram,
  EFinancingRefusalApproveMessage,
  ETasksStatus,
  EWorksheetDecision,
  VehicleEvaluationDTO,
  WorksheetDecision,
} from '../../data/types'
import { Document } from '../../data/types/DocumentSchema'
import { ParameterizedMessage } from '../../data/types/ParameterizedMessage'
import PrivateMessage from '../ViewCreditApplicationPage/components/privateMessage'
import WarningMessageAccordion from '../ViewCreditApplicationPage/components/warningMessageAccordion'
import EditDecisionDialog from './components/EditAutoWorksheetDecisionDialog'
import EditVehicleEvaluationDialog, { VehicleEvaluationFormData } from './components/EditVehicleEvaluationDialog'
import NormIcebergWorksheet from './components/normIcebergWorksheet'
import ProgramSection from './components/programSection'
import TradeInVehicleSummary from './components/tradeInVehicleSummary'
import TransactionSection from './components/TransactionSection'
import VehicleEvaluationBox from './components/vehicleEvaluationBox'
import VehicleSummary from './components/vehicleSummary'
import WorksheetDecisionComponent from './components/worksheetDecision'

const WorksheetDecisionPage = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const EditVehiculeEvaluationDialogOpen = 'EditVehiculeEvaluationDialogOpen'
  const EditDecisionDialogOpen = 'EditDecisionDialogOpen'

  const dispatchEffect = useSideEffect()

  const [currentOpenDialog, setCurrentOpenDialog] = useState<string>('')
  const [isVauto, setIsVauto] = React.useState(false)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const isLoading = useAppSelector(autoWorksheetSelectors.isLoadingWortsheet)
  const worksheet = useAppSelector(autoWorksheetSelectors.getCurrent)
  const privateMessages = useAppSelector(messageSelectors.getPrivateMessages)
  const error = useAppSelector(appSelectors.getBusinessError)
  const worksheetNormRefusalAndWarningMessages = useAppSelector(
    autoWorksheetSelectors.getCurrentWorksheetNormRefusalAndWarningMessages,
  )
  const worksheetNormWarningMessages = useAppSelector(autoWorksheetSelectors.getCurrentWorksheetNormWarningMessages)
  const documents = useAppSelector(documentSelectors.getDocumentList)
  const worksheetNormRefusalMessages = useAppSelector(autoWorksheetSelectors.getCurrentWorksheetNormRefusalMessages)

  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)
  const editDisabled: boolean = creditApplication?.editLocked === true || finalDecisionIsPending
  const areAllTasksRequiredForCVTCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForCVTCompleted)
  const areAllDocumentsRequiredForCVTApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredForCVT)
  const confirmAllIncomesStatus = useAppSelector(creditSelectors.getConfirmAllIncomesStatus)

  const computeCantApproveReasonCodes = () => {
    const message: EFinancingRefusalApproveMessage[] = []
    if (worksheetNormRefusalMessages.length > 0) message.push(EFinancingRefusalApproveMessage.NormRefusalMessage)
    if (!areAllTasksRequiredForCVTCompleted) message.push(EFinancingRefusalApproveMessage.TaskIncomplete)
    if (confirmAllIncomesStatus !== ETasksStatus.Completed)
      message.push(EFinancingRefusalApproveMessage.NotAllIcomeConfirmed)
    if (!areAllDocumentsRequiredForCVTApproved) message.push(EFinancingRefusalApproveMessage.MissingRequiredDocument)
    if (worksheet?.vehicle?.mileage != null && worksheet.vehicle.mileage >= 1000000)
      message.push(EFinancingRefusalApproveMessage.MileAgeOver999999)
    if (
      !creditApplication?.applicant.existingCustomerReference ||
      (creditApplication?.coApplicant && !creditApplication?.coApplicant.existingCustomerReference)
    )
      message.push(EFinancingRefusalApproveMessage.MissingExistingCustomerReference)
    if (
      worksheet?.carfaxValue != null &&
      worksheet?.carfaxValue > 4999 &&
      (worksheet?.vehicleEvaluation == null || worksheet.vehicleEvaluation.vehicleValue === 0 || !isVauto)
    )
      message.push(EFinancingRefusalApproveMessage.CarfaxOver4999WithoutVehicleValueOrVehicleEvalutionDocument)
    if (worksheet?.finalVehicleValue == null || worksheet.finalVehicleValue === 0)
      message.push(EFinancingRefusalApproveMessage.MissingVehicleValueOrThirdPartyVehicleValue)

    if (
      worksheet?.vehicleEvaluation?.vehicleValue == null ||
      (worksheet.vehicleEvaluation.vehicleValue > 0 && !isVauto)
    )
      message.push(EFinancingRefusalApproveMessage.MissingVehicleEvalutionDocument)

    return message
  }

  const autoWorksheetFinalDecision: WorksheetDecision = {
    decision: worksheet?.finalDecision?.decision as EWorksheetDecision,
    otherReason: worksheet?.finalDecision?.otherReason ? worksheet?.finalDecision?.otherReason : '',
    refusalReasonCodes: worksheet?.finalDecision ? worksheet?.finalDecision?.refusalMessages.map((x) => x.id) : [],
    cantApproveReasonCodes: computeCantApproveReasonCodes(),
    maxPmtAmount: worksheet?.fundingThresholdExtend?.maxPmtAmount ? worksheet?.fundingThresholdExtend?.maxPmtAmount : 0,
    maxTermDuration: worksheet?.fundingThresholdExtend?.maxTermDuration ?? 0,
    maxTotalAmountFinanced: worksheet?.fundingThresholdExtend?.maxTotalAmountFinanced ?? 0,
    id: undefined,
    decidorFullName: null,
    decidorId: null,
    refusalMessages: [],
    warningMessages: [],
  }

  const handleSaveVehicleEvaluationDTO = async (data: VehicleEvaluationDTO) => {
    await dispatchEffect(
      worksheetEffects.updateVehicleEvaluationDTO({
        ...data,
        creditApplicationId: worksheet?.creditApplicationId,
        versionTag: worksheet?.versionTag,
      }),
    ).then(() => setCurrentOpenDialog(''))
  }

  const handleSaveDecision = async (data: WorksheetDecision) => {
    if (worksheet) {
      const updatedFinalDecision: AutoWorksheetDecisionDTO = {
        creditApplicationId: worksheet.creditApplicationId,
        decision: data.decision,
        otherReason: data.otherReason,
        versionTag: worksheet.versionTag,
      } as AutoWorksheetDecisionDTO

      if (data.decision === EWorksheetDecision.Declined) {
        const refMessages: ParameterizedMessage[] = []
        data.refusalReasonCodes?.forEach((x) => {
          const refMessage = worksheet.finalDecision?.refusalMessages.find((y) => y.id === x)
          if (refMessage) refMessages.push(refMessage)
        })
        updatedFinalDecision.refusalMessages = refMessages
      }

      if (data.decision === EWorksheetDecision.ApprovedWithCondition) {
        const fundingThresholdExtend: BaseWorksheetFundingThresholdExtend = {
          maxPmtAmount: data.maxPmtAmount,
          maxTotalAmountFinanced: data.maxTotalAmountFinanced,
          maxTermDuration: data.maxTermDuration,
        }
        updatedFinalDecision.fundingThresholdExtend = fundingThresholdExtend
      }
      await dispatchEffect(
        worksheetEffects.updateDecision({
          ...updatedFinalDecision,
          creditApplicationId: worksheet?.creditApplicationId,
        }),
      ).then(() => setCurrentOpenDialog(''))
    }
  }

  React.useEffect(() => {
    if (creditApplication && worksheet?.vehicle) {
      dispatchEffect(
        vehicleEffects.getMaxTerm({
          year: Number(worksheet.vehicle.year),
          mileage: Number(worksheet.vehicle.mileage),
          plan: creditApplication.finalCreditDecision.planName,
        }),
      ).catch(reportErrorToConsole)
    }
  }, [worksheet?.vehicle, creditApplication, dispatchEffect])

  React.useEffect(() => {
    const vautoDoc = documents.find((x) => x.typeId === EDocumentType.VehicleValueEvaluation)
    setIsVauto(vautoDoc !== undefined)
  }, [documents])

  React.useEffect(() => {
    if (creditApplication) {
      dispatchEffect(documentEffects.getDocumentList(creditApplication.id, creditApplication.financingProgramId)).catch(
        reportErrorToConsole,
      )
    }
  }, [worksheet?.vehicleEvaluation, creditApplication, dispatchEffect])

  const sendDoc = React.useCallback(
    (form: FormData) => {
      return dispatchEffect(documentEffects.sendPrivateDocument(form))
    },
    [dispatchEffect],
  )

  const changeDoc = async (form: FormData) => {
    if (creditApplication) {
      const doc = documents.find((x) => x.typeId === EDocumentType.VehicleValueEvaluation)
      if (doc) await dispatchEffect(documentEffects.updatePrivateDocument(form, creditApplication.id, doc))
    }
  }

  const onDeleteDoc = React.useCallback(
    (doc: Document) => {
      return dispatchEffect(documentEffects.deletePrivateDocument(doc))
    },
    [dispatchEffect],
  )

  const handleFileConfirm = async (data: File) => {
    if (creditApplication) {
      const doc = {
        isPrivate: true,
        creditApplicationId: creditApplication.id,
        financingProgramId: creditApplication.financingProgramId,
        typeId: EDocumentType.VehicleValueEvaluation,
        status: EDocumentStatus.Approved,
        versionTag: documents.find((x) => x.typeId === EDocumentType.VehicleValueEvaluation)?.versionTag,
      } as Document
      const formData = new FormData()
      formData.set('file', data, data.name)
      formData.set('payload', JSON.stringify(doc))
      if (isVauto) {
        // PUT
        await changeDoc(formData)
      } else {
        // POST
        await sendDoc(formData)
      }
    }
  }
  const user = useAppSelector(userSelectors.getUser)

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${EFinancingProgram.Auto}/${creditApplication?.id}/view`,
      label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
    },
    { path: '#', label: t('worksheet.financing') },
  ]

  if (!worksheet || !creditApplication) return null

  const handleDeletingPrivateMessage = async (message: CreditApplicationMessage) => {
    if (creditApplication?.id) {
      await dispatchEffect(messageEffects.deleteMessage(message, creditApplication.financingProgramId))
    }
  }

  return (
    <div>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <Stack>
            <PageError errors={error} />
            {finalDecisionIsPending && <PendingMessage />}
            <ProgramSection worksheet={worksheet} creditApplication={creditApplication} merchant={merchant} />

            <PrivateMessage
              messages={privateMessages}
              handleDeletingPrivateMessage={handleDeletingPrivateMessage}
              currentUser={user}
              creditAppCreationDate={creditApplication.createdOn}
            />

            <WarningMessageAccordion
              applicantCreditWarnings={null}
              coapplicantCreditWarnings={null}
              commonCreditWarnings={null}
              worksheetWarnings={worksheetNormRefusalAndWarningMessages}
              expanded={false}
            />

            <Grid container textAlign="center" marginBottom={2} marginTop={2}>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <VehicleEvaluationBox
                  worksheet={worksheet}
                  onClick={() => setCurrentOpenDialog(EditVehiculeEvaluationDialogOpen)}
                  isVauto={isVauto}
                  editDisabled={editDisabled}
                  userCanEditWorksheet={user?.rights.canEditWorksheet === true}
                />
              </Grid>

              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <NormIcebergWorksheet worksheet={worksheet} />
              </Grid>

              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <WorksheetDecisionComponent
                  worksheet={worksheet}
                  onClick={() => setCurrentOpenDialog(EditDecisionDialogOpen)}
                  editDisabled={editDisabled}
                  userCanEditWorksheet={user?.rights.canEditWorksheet === true}
                />
              </Grid>
            </Grid>
            <Grid container marginBottom={2}>
              <Grid item xs={12} md={6} border="1px solid" borderColor={theme.palette.divider} borderRadius={2}>
                <VehicleSummary vehicle={worksheet.vehicle} />
              </Grid>

              {worksheet.tradeInVehicle && (
                <Grid item xs={12} md={6} border="1px solid" borderColor={theme.palette.divider} borderRadius={2}>
                  <TradeInVehicleSummary vehicle={worksheet.tradeInVehicle} />
                </Grid>
              )}
            </Grid>
            <TransactionSection worksheet={worksheet} creditApplication={creditApplication} />
          </Stack>

          <EditDecisionDialog
            open={currentOpenDialog === EditDecisionDialogOpen}
            title={t('worksheet.decision')}
            onCancel={() => setCurrentOpenDialog('')}
            onConfirm={async (data: WorksheetDecision) => {
              await handleSaveDecision(data)
            }}
            worksheetWarnings={worksheetNormWarningMessages}
            defaultValue={autoWorksheetFinalDecision}
            worksheet={worksheet}
            creditApplication={creditApplication}
          />

          <EditVehicleEvaluationDialog
            onCancel={() => {
              setCurrentOpenDialog('')
            }}
            onConfirm={async (data: VehicleEvaluationFormData) => {
              if (data.vehicleEvaluationDTO) {
                await handleSaveVehicleEvaluationDTO(data.vehicleEvaluationDTO)
                if (data.vehicleEvaluationFile) {
                  await handleFileConfirm(data.vehicleEvaluationFile)
                } else {
                  setCurrentOpenDialog('')
                }
              }
            }}
            open={currentOpenDialog === EditVehiculeEvaluationDialogOpen}
            title={t('worksheet.sideSection.vehicleEvaluation')}
            initialInternalEvaluationValue={
              {
                vehicleValue: worksheet?.vehicleEvaluation?.vehicleValue,
                discretionaryAllocation: worksheet?.vehicleEvaluation?.discretionaryAllocation,
                carfaxValue: worksheet.carfaxValue,
              } as VehicleEvaluationDTO
            }
            vautoDocument={documents.find((x) => x.typeId === EDocumentType.VehicleValueEvaluation) ?? null}
            onDeleteDoc={onDeleteDoc}
          />
        </>
      )}
    </div>
  )
}

export default WorksheetDecisionPage
