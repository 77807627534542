import { sumBy } from 'lodash-es'
import { ApplicantJob, ApplicantOtherIncome, BusinessError, CreditApplication } from '../../types'
import { Constants, ERelationToApplicant } from '../../types/constants'

function strCompare(param1: string | null | undefined, param2: string | null | undefined): boolean {
  return (param1 ?? '') === (param2 ?? '')
}

function DoesApplicantLiveWithCoapplicant(application: CreditApplication): boolean {
  const applicantAddress = application.applicant.currentAddress
  const coApplicantAddress = application.coApplicant?.currentAddress

  return (
    strCompare(applicantAddress.civicNumber, coApplicantAddress?.civicNumber) &&
    strCompare(applicantAddress.apartment, coApplicantAddress?.apartment) &&
    strCompare(applicantAddress.street, coApplicantAddress?.street) &&
    strCompare(applicantAddress.city, coApplicantAddress?.city) &&
    strCompare(applicantAddress.country, coApplicantAddress?.country) &&
    strCompare(applicantAddress.stateIso, coApplicantAddress?.stateIso) &&
    strCompare(applicantAddress.postalCode, coApplicantAddress?.postalCode)
  )
}

export default function validateCreditApplicationCanBeSubmittedForReview(
  application: CreditApplication,
): BusinessError[] {
  const ret: BusinessError[] = []
  const applicantIncomes =
    sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary) +
    sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount)

  const coapplicantIncomes =
    sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary) +
    sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount)

  const minIncome = Constants.GetMinIncomeForProgram(application.financingProgramId)
  const minCombinedIncome = Constants.GetMinCombinedIncomeForProgram(application.financingProgramId)
  const coapplicantIsSpouse =
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.Spouse ||
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.CommonLaw

  if (coapplicantIsSpouse && DoesApplicantLiveWithCoapplicant(application)) {
    if (
      applicantIncomes < minIncome &&
      coapplicantIncomes < minIncome &&
      applicantIncomes + coapplicantIncomes < minCombinedIncome
    )
      ret.push({
        message: 'editCreditApplication.errors.minIncomeNotMetCouple',
        params: { minIncome: minIncome.toString(), minCombinedIncome: minCombinedIncome.toString() },
      })
  } else if (applicantIncomes < minIncome && coapplicantIncomes < minIncome)
    ret.push({ message: 'editCreditApplication.errors.minIncomeNotMet', params: { minIncome: minIncome.toString() } })

  return ret
}
